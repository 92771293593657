import React from 'react';
import { graphql } from 'gatsby';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import Icon from '../components/icon/icon';
import Seo from '../components/seo/seo';

const ContactPage = () => {
  return (
    <Layout page="contact">
      <Seo title="Kapcsolat" />
      <div className="contact container-fluid">
        <div className="page-content centered">
          <h1 className="page-title"><Trans>contact</Trans></h1>

          <address className="address">
            <div className="address-section">
              <div className="address-header">
                <Icon className="icon" name="map-pin" size={24} />
                <strong><Trans>shopLocation</Trans></strong>
              </div>
              <div className="address-body"><Trans>address</Trans></div>
            </div>
            <div className="address-section">
              <div className="address-header">
                <Icon className="icon" name="mail" size={24} />
                <strong>E-mail</strong>
              </div>
              <div className="address-body">
                <a href="mailto:marabutor2@gmail.com">marabutor2@gmail.com</a><br />
              </div>
            </div>
            <div className="address-section">
              <div className="address-header">
                <Icon className="icon" name="phone" size={24} />
                <strong><Trans>phone</Trans></strong>
              </div>
              <div className="address-body">
                <a href="tel:+36706381198">+36 70 638 1198 &ndash; <Trans>maria</Trans></a> <br/>
                <a href="tel:+36706357118">+36 70 635 7118 &ndash; <Trans>mike</Trans></a>
              </div>
            </div>
          </address>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;